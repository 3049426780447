<template>
  <div class="item">
    <v-container id="como-funciona" class="pa-0">
      <TitleWidget class="white--text item-title"> Como funciona </TitleWidget>

      <div class="px-lg-16 px-xl-0">
        <CarouselWidget
          v-if="items.length"
          class="mt-6 mt-md-10"
          :items="items"
          spacing="px-sm-3"
          :itemsToShow="itemsToShow"
          :infiniteScroll="false"
          :autoPlay="false"
          nav
        >
          <template #item="{ item }">
            <HowWorkCard v-bind="item" />
          </template>
        </CarouselWidget>
      </div>
    </v-container>
  </div>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import CarouselWidget from "../widgets/CarouselWidget.vue";
import HowWorkCard from "../cards/HowWorkCard.vue";

const _config = {
  mod: "howWorks",
  title: "Como funciona",
};

export default {
  data: () => ({
    title: _config.title,
    itemsToShow: {
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4,
    },
  }),
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ title, text }, index) => ({
        itemNumber: String(index + 1).padStart(2, "0"),
        title,
        text,
      }));
    },
  },
  components: {
    TitleWidget,
    CarouselWidget,
    HowWorkCard,
  },
};
</script>

<style lang="scss" scoped>
.item {
  --padding: 120px;

  @media screen and (max-width: 960px) {
    --padding: 90px;
  }

  @media screen and (max-width: 600px) {
    --padding: 60px;
  }

  background: linear-gradient(130deg, #005b73 0%, #087977 100%);
  padding-bottom: var(--padding);
  padding-top: var(--padding);
}
</style>

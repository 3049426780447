<template>
  <v-autocomplete
    :rules="rules"
    v-model="model"
    v-bind="fieldProps"
    :items="items"
    :loading="loading"
    :disabled="loading"
    name="category"
    label="Qual foi o problema com seu voo?"
  ></v-autocomplete>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

export default {
  props: {
    complaint: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    rules: [requiredValidation("Selecione o motivo da reclamação.")],
    loading: false,
    model: null,
  }),
  mixins: [mixin],
  computed: {
    items() {
      return this.$rest("complaintsList").list.map(({ title }) => {
        return title;
      });
    },
  },
  watch: {
    complaint(v) {
      this.model = v;
    },
  },
  created() {
    this.model = this.complaint;
  },
};
</script>

import { render, staticRenderFns } from "./Mobile.vue?vue&type=template&id=4b704a72&scoped=true&"
import script from "./Mobile.vue?vue&type=script&lang=js&"
export * from "./Mobile.vue?vue&type=script&lang=js&"
import style0 from "./Mobile.vue?vue&type=style&index=0&id=4b704a72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b704a72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAppBar,VAppBarNavIcon,VDivider,VList,VListItem,VListItemContent,VListItemTitle,VNavigationDrawer,VSheet})

<template>
  <v-autocomplete
    :rules="rules"
    v-model="model"
    v-bind="fieldProps"
    class="form-control"
    name="company"
    label="Companhia Aérea"
    :items="items"
    hide-no-data
    item-text="title"
    item-value="title"
  >
  </v-autocomplete>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

const _config = {
  mod: "airlines",
};

const _data = {
  search: null,
  filteredItems: [],
  model: null,
};

export default {
  props: {
    company: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    ..._data,
    rules: [requiredValidation("Selecione a companhia aérea.")],
  }),
  mixins: [mixin],
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ img, title }) => ({
        img: this.files + img,
        title,
      }));
    },
  },
  watch: {
    company(v) {
      this.model = v;
    },
  },
  created() {
    this.model = this.company;

    this.$rest(_config.mod).get();
  },
};
</script>

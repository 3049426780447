<template>
  <div>
    <v-dialog
      v-model="open"
      content-class="privacy"
      max-width="800px"
      scrollable="scrollable"
    >
      <div class="relative">
        <div class="px-4 py-6 privacy-card white" height="80vh">
          <PolicyPrivacyWidget title-size="text-h6 text-md-h5" />
        </div>
        <v-btn
          @click="open = false"
          class="mt-n1"
          color="red"
          icon="icon"
          absolute="absolute"
          top="top"
          right="right"
        >
          <span class="times">&times;</span>
        </v-btn>
      </div>
    </v-dialog>
    <v-snackbar class="pa-0 vcb-banner-wrap" v-model="value" :timeout="-1">
      <div class="vcb-banner">
        <div class="vcb-banner-inner align-center">
          <div class="text-left text-caption font-weight-bold">
            Nós utilizamos cookies e outras tecnologias semelhantes para
            melhorar a sua experiência. Ao utilizar nossa plataforma você
            concorda com tal monitoramento. Saiba mais acessando nossa
            <a class="ml-1" @click="showPrivacy">Política de Privacidade</a>.
          </div>
          <v-btn
            class="font-weight-bold vcb-btn text-none"
            @click="consent(true)"
            depressed="depressed"
          >
            <span class="text-caption font-weight-bold"> Prosseguir</span>
          </v-btn>
        </div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import PolicyPrivacyWidget from "./PolicyPrivacyWidget.vue";

function setCookie(name, value, exdays) {
  const d = new Date();

  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name) {
  name = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return null;
}

export default {
  data: () => ({
    value: false,
    open: false,
  }),
  methods: {
    showPrivacy() {
      this.open = true;
    },
    consent(v) {
      setCookie("_privacy_policy_consent", v ? "true" : "false", 365);
      this.value = false;
    },
  },
  created() {
    if (!getCookie("_privacy_policy_consent")) this.value = true;
    window.showPrivacyPolicy = () => this.showPrivacy();
  },
  components: {
    PolicyPrivacyWidget,
  },
};
</script>

<style>
.vcb-banner {
  background-color: var(--vcb-banner-bg-color, white);
  color: var(--vcb-banner-text-color, #222);
}

.vcb-banner-wrap .v-snack__wrapper {
  background-color: var(--vcb-banner-bg-color, white) !important;
  max-width: initial !important;
}

.vcb-banner a,
.vcb-banner a:link,
.vcb-banner a:hover {
  color: var(--vcb-banner-a-color, var(--v-primary-base)) !important;
}

.vcb-btn {
  background-color: var(
    --vcb-banner-btn-bg-color,
    var(--v-primary-base)
  ) !important;
  color: var(--vcb-banner-btn-text-color, white) !important;
}

.vcb-banner-inner {
  display: grid;
  grid-template-columns: auto max-content;
  gap: 12px;
}

.vcb-banner-inner::before,
.vcb-banner-inner::after {
  content: initial;
}

.times {
  font-size: 28px;
}

.relative {
  position: relative;
}

.privacy-card {
  overflow-y: scroll;
  height: 80vh;
}

@media screen and (max-width: 600px) {
  .vcb-banner-inner {
    grid-template-columns: auto;
    gap: 12px;
  }
}

@media (min-width: 1904px) {
  .vcb-banner-inner {
    max-width: 1785px;
  }
}

@media (min-width: 1264px) {
  .vcb-banner-inner {
    max-width: 1185px;
  }
}

@media (min-width: 960px) {
  .vcb-banner-inner {
    max-width: 900px;
  }
}

.vcb-banner-inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
</style>

<template>
  <div>
    <v-sheet v-if="items.length" color="secondary lighten-2">
      <v-container
        id="conheca-os-seus-direitos"
        class="py-10 py-md-13 py-lg-16"
      >
        <TitleWidget class="secondary--text text--lighten-1">
          {{ title }}
        </TitleWidget>

        <div class="justify-center mt-7 grid-list">
          <template v-for="(item, keyItems) in items">
            <ArticleCard :key="keyItems" v-bind="item" />
          </template>
        </div>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ArticleCard from "../components/cards/ArticleCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "articles",
  title: "Conheça os seus Direitos",
  path: "/conheca-os-seus-direitos",
};

export default {
  data: () => ({
    title: _config.title,
  }),
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, icon, title, text }) => ({
        icon,
        title,
        to: text ? `${_config.path}/${formatToURL(title)}-${id}` : "",
      }));
    },
  },
  components: {
    TitleWidget,
    ArticleCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(0, 215px));

  @media screen and (max-width: 600px) {
    gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 215px));
  }
}
</style>

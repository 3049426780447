<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget class="secondary--text text--lighten-1">
      {{ item.title }}
    </TitleWidget>

    <v-row justify="center">
      <v-col cols="12" md="9">
        <img
          v-if="item.img"
          class="d-block mt-8"
          :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 1200)"
          :alt="item.title"
          width="100%"
        />
      </v-col>
    </v-row>

    <div
      v-if="item.text"
      class="text-subtitle-1 mt-8 ck-content item-text"
      v-html="item.text"
    ></div>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";

const _config = {
  mod: "about",
};

export default {
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    TitleWidget,
  },
};
</script>

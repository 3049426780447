<template>
  <div class="item">
    <v-sheet class="px-3 mx-auto" color="transparent" max-width="990px">
      <div
        class="
          white--text
          text-subtitle-1 text-md-h6 text-justify
          mb-6 mb-sm-10
        "
      >
        O Reclame PASSAGEIRO está disponível para receber sua reclamação,
        fornecer orientações sobre seus direitos e oferecer instruções
        detalhadas para lidar com cada situação.
      </div>

      <SearchForm />
    </v-sheet>
  </div>
</template>

<script>
import SearchForm from "../forms/SearchForm.vue";

export default {
  components: {
    SearchForm,
  },
};
</script>

<style lang="scss" scoped>
.item {
  --padding: 60px;

  background: linear-gradient(130deg, #005b73 0%, #087977 100%);
  padding-bottom: var(--padding);
  padding-top: calc(var(--padding) - 15px);

  @media screen and (max-width: 599.9px) {
    --padding: 40px;
    padding-top: calc(var(--padding) - 10px);
  }
}
</style>

<template>
  <div class="item">
    <v-container id="como-funciona" class="pa-0">
      <TitleWidget class="white--text item-title"> Como funciona </TitleWidget>

      <div class="mt-6 mt-md-10 justify-center grid-list">
        <template v-for="(item, keyItems) in items">
          <HowWorkCard :key="keyItems" v-bind="item" />
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import HowWorkCard from "../components/cards/HowWorkCard.vue";

const _config = {
  mod: "howWorks",
  title: "Como funciona",
};

export default {
  data: () => ({
    title: _config.title,
  }),
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ title, text }, index) => ({
        itemNumber: String(index + 1).padStart(2, "0"),
        title,
        text,
      }));
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    TitleWidget,
    HowWorkCard,
  },
};
</script>

<style lang="scss" scoped>
.item {
  --padding: 120px;

  @media screen and (max-width: 960px) {
    --padding: 90px;
  }

  @media screen and (max-width: 600px) {
    --padding: 60px;
  }

  background: linear-gradient(130deg, #005b73 0%, #087977 100%);
  padding-bottom: var(--padding);
  padding-top: var(--padding);

  .grid-list {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(0, 298px));

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: minmax(0, 298px);
    }
  }
}
</style>

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#004273",
          darken2: "#003359",
        },
        secondary: {
          lighten4: "#EAF2F6",
          lighten2: "#D5E7EB",
          lighten1: "#005C73",
          base: "#00CC9C",
        },
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "fa",
  },
});

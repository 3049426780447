<template>
  <div>
    <LoadingWidget v-if="loading" />

    <v-container
      v-else-if="items.length"
      id="ultimas-reclamacoes"
      class="py-10 py-md-13 py-lg-16 px-0"
    >
      <TitleWidget class="primary--text">
        {{ title }}
      </TitleWidget>

      <v-sheet
        class="d-flex flex-column mx-auto mt-8 mt-md-10 items"
        max-width="990px"
        width="100%"
      >
        <template v-for="(item, keyItems) in parsedItems">
          <ClaimCard :key="keyItems" v-bind="item" />
        </template>
      </v-sheet>

      <v-pagination
        v-if="(showMore && pagesLength > 1) || page > 1"
        class="mt-10"
        color="secondary"
        disabled
        v-model="page"
        :length="pagesLength"
        :total-visible="totalVisible"
      ></v-pagination>

      <ButtonWidget
        v-if="!showMore && page == 1"
        @click="showMore = !showMore"
        class="mt-6 mt-md-8"
        align="center"
      >
        Mais reclamações
      </ButtonWidget>
    </v-container>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import ClaimCard from "../cards/ClaimCard.vue";
import ButtonWidget from "../widgets/ButtonWidget.vue";

const _config = {
  mod: "claims",
};

const _pagination = {
  itemsPerPage: 12,
  returnTotalItems: 1,
};

const _data = {
  title: "Últimas Reclamações",
  loading: true,
  totalItems: 0,
  showMore: false,
  maxVisibleButtons: 4,
  currentPage: 1,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.getList();
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / _pagination.itemsPerPage);
    },
    totalVisible() {
      return 4;
    },
    parsedItems() {
      return this.items.filter((item, index) =>
        !this.showMore && this.page === 1 ? index <= 5 : true
      );
    },
    items() {
      let count = 6;
      return this.$rest(_config.mod).list.map(({ title, text }, index) => {
        const item = {
          title,
          text,
          answered: index <= 1 ? 0 : 1,
        };

        item.time = index <= 1 ? 1 : Math.floor(count / 3, 1);

        if (index > 1) count++;

        return item;
      });
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        ..._pagination,
      };

      this.$rest(_config.mod)
        .get({
          params,
          save: (state, data) => {
            if (_pagination.returnTotalItems) {
              state.list = data.list;
              this.totalItems = data.totalItems;
            } else state.list = data;
          },
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    page() {
      this.getList();
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    ClaimCard,
    ButtonWidget,
  },
};
</script>

<style lang="scss" scoped>
.items {
  gap: 30px;

  @media screen and (max-width: 599.9px) {
    gap: 20px;
  }
}
</style>

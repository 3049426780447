<template>
  <v-app-bar class="header" color="white" height="198px" flat tile>
    <v-sheet
      class="d-flex flex-column justify-center align-center px-3"
      color="primary"
      width="100%"
      height="150px"
    >
      <router-link to="/">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          height="80px"
          class="d-block mt-3"
        />
      </router-link>
    </v-sheet>

    <v-sheet color="primary darken-2" width="100%" height="40px">
      <v-container class="py-0">
        <div class="d-flex justify-end">
          <div
            class="d-flex align-center"
            v-for="({ text, to }, keyMenu) in menu"
            :key="keyMenu"
          >
            <v-sheet
              v-if="keyMenu > 0"
              color="white"
              width="1px"
              height="24px"
            ></v-sheet>

            <v-btn
              class="
                text-body-2 text-uppercase
                font-weight-medium
                px-6
                nav-link
              "
              color="white"
              :to="to"
              height="40px"
              depressed
              text
            >
              {{ text }}
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet color="secondary" width="100%" height="8px"></v-sheet>
  </v-app-bar>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../../utils/getWhatsappLinkFromPhone";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    instagram() {
      return (
        "@" + new URL(this.informations.instagram).pathname.replaceAll("/", "")
      );
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.header::v-deep {
  .v-toolbar__content {
    flex-direction: column;
    padding: 0;
  }
}

.instagram {
  background: radial-gradient(
      61.46% 59.09% at 36.25% 96.55%,
      #ffd600 0%,
      #ff6930 48.44%,
      #fe3b36 73.44%,
      rgba(254, 59, 54, 0) 100%
    ),
    radial-gradient(
      202.83% 136.37% at 84.5% 113.5%,
      #ff1b90 24.39%,
      #f80261 43.67%,
      #ed00c0 68.85%,
      #c500e9 77.68%,
      #7017ff 89.32%
    ) !important;
}

.nav-link {
  &:before {
    opacity: 0 !important;
  }
}
</style>

<template>
  <div class="claim-form">
    <v-form @submit="submit" ref="form">
      <div class="base-form-fields">
        <v-stepper v-model="step" flat>
          <v-stepper-items>
            <v-stepper-content class="step-content" step="1">
              <CompanyField :company="company" />
              <ClaimCategoryField :complaint="complaint" />
              <ClaimWhatField />
              <ClaimTitleField />
              <ClaimHistoryField />

              <ButtonWidget @click="nextStep" align="center" type="button">
                Continuar
              </ButtonWidget>
            </v-stepper-content>

            <v-stepper-content class="step-content" step="2">
              <template v-if="step === 2">
                <FullNameField />
                <WhatsAppField />
                <PolicyPrivacyField />
              </template>

              <ButtonWidget align="center" type="submit">
                Reclamar
              </ButtonWidget>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-form>
  </div>
</template>

<script>
import CompanyField from "./fields/CompanyField.vue";
import ClaimCategoryField from "./fields/ClaimCategoryField.vue";
import ClaimWhatField from "./fields/ClaimWhatField.vue";
import ClaimTitleField from "./fields/ClaimTitleField.vue";
import ClaimHistoryField from "./fields/ClaimHistoryField.vue";
import FullNameField from "./fields/FullNameField.vue";
import WhatsAppField from "./fields/WhatsAppField.vue";
import PolicyPrivacyField from "./fields/PolicyPrivacyField.vue";
import ButtonWidget from "../widgets/ButtonWidget.vue";

const _data = {
  step: 1,
  gtmSubmitEvent: "form_submit_claim",
};

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    responseTo: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      default: "",
    },
    complaint: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    ..._data,
  }),
  methods: {
    formValidate() {
      const form = this.$refs.form;

      return form.validate();
    },
    nextStep() {
      if (this.formValidate()) this.step = 2;
    },
    async submit(event) {
      event.preventDefault();

      if (this.formValidate()) {
        this.loading = true;

        const form = this.$refs.form;
        const formData = new FormData(form.$el);

        try {
          const { ok } = await fetch(this.action, {
            method: "POST",
            body: formData,
          });

          if (ok) {
            if (window.dataLayer && this.gtmSubmitEvent) {
              window.dataLayer.push({ event: this.gtmSubmitEvent });
            }

            if (this.responseText) {
              form.reset();

              this.notificate(this.responseText, "green");
            } else if (this.responseTo) {
              this.$router.push(this.responseTo);
            }
          } else {
            throw new Error();
          }
        } catch {
          this.notificate(
            "Não foi possível enviar a mensagem. Tente novamente mais tarde",
            "red"
          );
        }

        this.loading = false;
      }
    },
  },
  components: {
    CompanyField,
    ClaimTitleField,
    ClaimCategoryField,
    ClaimWhatField,
    ClaimHistoryField,
    FullNameField,
    WhatsAppField,
    PolicyPrivacyField,
    ButtonWidget,
  },
};
</script>

<style lang="scss">
.claim-form {
  .base-form-fields {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
  }
}

.step-content {
  padding: 0 !important;

  .v-stepper__wrapper {
    padding: 16px !important;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Error404View from "../views/Error404View.vue";
import AboutView from "../views/AboutView.vue";
import HowWorkView from "../views/HowWorkView.vue";
import ArticlesListView from "../views/ArticlesListView.vue";
import ArticlesItemView from "../views/ArticlesItemView.vue";
import TermsView from "../views/TermsView.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import ClaimResponseFormView from "../views/ClaimResponseFormView.vue";
// import UnderConstructionView from "../views/UnderConstructionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Início",
    path: "/",
    component: HomeView,
  },
  {
    name: "Como Funciona",
    path: "/como-funciona",
    component: HowWorkView,
  },
  {
    name: "Conheça os Seus Direitos",
    path: "/conheca-os-seus-direitos",
    component: ArticlesListView,
  },
  {
    name: "Conheça os seus Direitos",
    path: "/conheca-os-seus-direitos/:item",
    component: ArticlesItemView,
  },
  {
    name: "Sobre Nós",
    path: "/sobre-nos",
    component: AboutView,
  },
  {
    name: "Termos e Condições de Uso",
    path: "/termos-e-condicoes-de-uso",
    component: TermsView,
  },
  {
    name: "Política de Privacidade e uso de Dados Pessoais",
    path: "/politica-de-privacidade-e-uso-de-dados-pessoais",
    component: PolicyPrivacyView,
  },
  {
    name: "Obrigado!",
    path: "/obrigado",
    component: ClaimResponseFormView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "*",
    name: "Erro 404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;

<template>
  <v-textarea
    :rules="rules"
    v-bind="fieldProps"
    no-resize
    name="message"
    label="Conte a sua história"
  ></v-textarea>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

export default {
  data: () => ({
    rules: [requiredValidation("Conte a sua história.")],
  }),
  mixins: [mixin],
};
</script>

<template>
  <div>
    <TitleWidget class="secondary--text text--lighten-1">
      {{ item.title }}
    </TitleWidget>

    <div class="text-body-1 mt-6 ck-content" v-html="item.text"></div>
  </div>
</template>

<script>
import TitleWidget from "./TitleWidget.vue";

const _config = {
  mod: "policyPrivacy",
};

export default {
  props: {
    titleSize: {
      type: String,
      default: "",
    },
  },
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
  },
  components: {
    TitleWidget,
  },
};
</script>

<template>
  <div>
    <Desktop v-if="!mobile" :menu="menu" />
    <Mobile v-else :menu="menu" />
  </div>
</template>

<script>
import Desktop from "./Desktop.vue";
import Mobile from "./Mobile.vue";
import { menu } from "../../../config";

export default {
  data: () => ({
    menu,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  components: {
    Desktop,
    Mobile,
  },
};
</script>

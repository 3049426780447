<template>
  <v-card
    @click="isClamp ? (clamped = !clamped) : ''"
    class="py-2 px-3 item"
    :class="{ 'cursor-default': !isClamp }"
    color="#f5f5f5"
    v-ripple="isClamp"
    flat
    tile
  >
    <div class="text-body-1 text-sm-subtitle-1 font-weight-black">
      {{ title }}
    </div>

    <div class="position-relative">
      <div
        ref="text"
        class="
          grey--text
          text-body-2 text-sm-body-1 text-justify
          mt-1
          ck-content
          item-text
        "
        :class="{ clamped: clamped }"
        v-html="text"
      ></div>

      <div
        v-if="isClamp && clamped"
        class="secondary--text text-body-2 text-sm-body-1 read-more"
      >
        <span class="grey--text">...</span> Ler mais
      </div>
    </div>

    <div class="d-flex align-center mt-3">
      <v-btn
        class="white--text"
        :color="answeredConfig.color"
        depressed
        rounded
        small
      >
        <v-icon class="mr-2" color="white" size="18">
          {{ answeredConfig.icon }}
        </v-icon>
        {{ answeredConfig.text }}
      </v-btn>

      <div class="grey--text text--darken-1 text-body-2 ml-3">
        {{ answeredConfig.time }}
      </div>
    </div>
  </v-card>
</template>

<script>
const _data = {
  clamped: true,
  isClamp: false,
};

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    answered: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    ..._data,
  }),
  computed: {
    answeredConfig() {
      if (this.answered)
        return {
          color: "#00ab4f",
          icon: "far fa-smile",
          text: "Respondida",
          time: `Há ${this.time} horas`,
        };

      return {
        color: "#f62d18",
        icon: "far fa-frown",
        text: "Não respondida",
        time: "Há 1 hora",
      };
    },
  },
  methods: {
    showMore() {
      const element = this.$refs.text;
      if (
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth
      )
        this.isClamp = true;
    },
  },
  mounted() {
    this.showMore();
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-text {
    max-height: 1000px;
    transition: max-height 0.1s linear;

    &.clamped {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      max-height: 96px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .read-more {
    background-color: #f5f5f5;
    bottom: 0;
    padding-right: 10px;
    position: absolute;
    right: 0;
    transition: 0.2s all;
  }

  &:focus {
    .read-more {
      background-color: #e4e4e4;
    }
  }
}
</style>

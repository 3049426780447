import { render, staticRenderFns } from "./ClaimSection.vue?vue&type=template&id=7977ad7a&scoped=true&"
import script from "./ClaimSection.vue?vue&type=script&lang=js&"
export * from "./ClaimSection.vue?vue&type=script&lang=js&"
import style0 from "./ClaimSection.vue?vue&type=style&index=0&id=7977ad7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7977ad7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VContainer,VPagination,VSheet})

<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <PolicyPrivacyWidget />
  </v-container>
</template>

<script>
import PolicyPrivacyWidget from "../components/widgets/PolicyPrivacyWidget.vue";

export default {
  components: {
    PolicyPrivacyWidget,
  },
};
</script>

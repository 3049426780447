<template>
  <div>
    <LoadingWidget v-if="loading" />

    <v-sheet v-else-if="items.length" color="secondary lighten-2">
      <v-container class="py-10 py-md-13 py-lg-16">
        <TitleWidget class="secondary--text text--lighten-1">
          {{ title }}
        </TitleWidget>

        <div v-if="text" class="grey--text text--darken-2 text-center mt-3">
          {{ text }}
        </div>

        <CarouselWidget
          v-if="items.length"
          class="mt-6 mt-md-10"
          :items="items"
          spacing="px-sm-3"
          :itemsToShow="itemsToShow"
          :infiniteScroll="false"
          :autoPlay="false"
          nav
        >
          <template #item="{ item }">
            <EvaluationCard v-bind="item" />
          </template>
        </CarouselWidget>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import CarouselWidget from "../widgets/CarouselWidget.vue";
import EvaluationCard from "../cards/EvaluationCard.vue";

const _config = {
  mod: "evaluations",
};

const _data = {
  loading: true,
  title: "Clientes Satisfeitos",
  text: "Veja avaliações de alguns de nossos clientes.",
  itemsToShow: {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
  },
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(
        ({ img, title, notation, text }) => ({
          img,
          title,
          notation: notation && notation.title ? Number(notation.title) : 0,
          text,
        })
      );
    },
  },
  created() {
    this.$rest(_config.mod)
      .get()
      .finally(() => (this.loading = false));
  },
  components: {
    LoadingWidget,
    TitleWidget,
    CarouselWidget,
    EvaluationCard,
  },
};
</script>

<template>
  <div>
    <v-app-bar
      class="header-mobile"
      elevate-on-scroll
      color="primary"
      height="150px"
    >
      <v-sheet
        class="d-flex flex-column justify-center align-center px-3"
        color="primary"
        width="100%"
        height="122px"
      >
        <router-link to="/">
          <img
            v-if="informations.img"
            :src="files + informations.img"
            :alt="informations.name"
            class="d-block mt-3 logotipo"
          />
        </router-link>
      </v-sheet>

      <v-sheet
        class="d-flex align-center justify-space-between px-3"
        color="primary darken-2"
        width="100%"
        height="40px"
      >
        <v-app-bar-nav-icon
          height="40px"
          width="40px"
          color="white"
          @click="drawer = !drawer"
        />
      </v-sheet>

      <v-sheet color="secondary" width="100%" height="8px"></v-sheet>
    </v-app-bar>

    <v-navigation-drawer
      class="header-mobile-navigation"
      color="primary"
      v-model="drawer"
      fixed
      temporary
    >
      <router-link to="/" class="d-flex px-2 py-4">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          class="d-block mx-auto navbar-brand"
        />
      </router-link>

      <v-divider />

      <v-list v-model="current" class="mt-2 px-0" nav>
        <template v-for="({ text, to }, keyMenu) in menu">
          <v-list-item class="mb-0 mt-2" :key="keyMenu" :to="to" link>
            <v-list-item-content>
              <v-list-item-title
                class="white--text text-body-2 font-weight-medium px-4"
              >
                {{ text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../../utils/getWhatsappLinkFromPhone";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: this.$route.path,
      drawer: false,
    };
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.header-mobile::v-deep {
  .v-toolbar__content {
    flex-direction: column;
    padding: 0;
  }
}

.header-mobile-navigation {
  .navbar-brand {
    width: 90%;
  }
}

.logotipo {
  max-width: 495px;
  width: 90vw;
}

.instagram {
  background: radial-gradient(
      61.46% 59.09% at 36.25% 96.55%,
      #ffd600 0%,
      #ff6930 48.44%,
      #fe3b36 73.44%,
      rgba(254, 59, 54, 0) 100%
    ),
    radial-gradient(
      202.83% 136.37% at 84.5% 113.5%,
      #ff1b90 24.39%,
      #f80261 43.67%,
      #ed00c0 68.85%,
      #c500e9 77.68%,
      #7017ff 89.32%
    ) !important;
}

.dropdown-menu::v-deep {
  .v-icon {
    font-size: 14px !important;
    color: white !important;
  }

  .v-list-group__header {
    margin-bottom: 0 !important;
    margin-top: 8px;
  }

  .v-list-group__items {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>

<template>
  <v-card
    class="px-3 px-sm-5 py-5 py-sm-7 item"
    color="secondary lighten-4"
    :to="to"
    flat
  >
    <img
      class="d-block mx-auto rounded-0"
      :src="files + icon + '?resize=1&h=35'"
      :alt="title"
      height="35px"
    />

    <div class="grey--text text-body-1 text-center mt-4">
      {{ title }}
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 14px !important;
}
</style>

<template>
  <div>
    <SearchSection />
    <EvaluationSection />
    <ClaimSection />
    <HowWorkSection />
    <ArticlesSection />
    <CommonQuestions />
  </div>
</template>

<script>
import SearchSection from "../components/sections/SearchSection.vue";
import EvaluationSection from "../components/sections/EvaluationSection.vue";
import ClaimSection from "../components/sections/ClaimSection.vue";
import HowWorkSection from "../components/sections/HowWorkSection.vue";
import ArticlesSection from "../components/sections/ArticlesSection.vue";
import CommonQuestions from "../components/sections/CommonQuestions.vue";

export default {
  components: {
    SearchSection,
    EvaluationSection,
    ClaimSection,
    HowWorkSection,
    ArticlesSection,
    CommonQuestions,
  },
};
</script>

<template>
  <v-container v-if="items.length" class="py-10 py-md-13 py-lg-16">
    <TitleWidget class="primaty--text">
      {{ title }}
    </TitleWidget>

    <div class="mt-6 mt-md-8">
      <v-expansion-panels class="common-questions" focusable accordion>
        <v-expansion-panel
          v-for="({ title, text }, keyItems) in items"
          :key="keyItems"
        >
          <v-expansion-panel-header>
            <div
              class="grey--text text--darken-3 text-body-1 font-weight-medium"
            >
              {{ title }}
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div
              class="grey--text text-body-2 pt-3 ck-content"
              v-html="text"
            ></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";

const _config = {
  mod: "commonQuestions",
};

const _data = {
  title: "Perguntas Frequentes",
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list;
    },
  },
  created() {
    this.$rest(_config.mod).get();
  },
  components: {
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.common-questions::v-deep {
  .v-expansion-panel {
    border-radius: 0 !important;

    &:before {
      box-shadow: none !important;
    }

    .v-expansion-panel-header {
      min-height: 0 !important;
    }
  }

  &:not(:last-child) {
    .v-expansion-panel {
      border-bottom: 1px solid #cccccc !important;
    }
  }
}
</style>

export function searchTest(search, text) {
  const escapePattern = (s) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  const iLikeToRegExp = (pattern) =>
    new RegExp(
      `^${escapePattern(pattern)}$`
        .replace(/(?<![\\])%/g, ".*")
        .replace(/(?<![\\])_/g, ".")
        .replace(/\\%/g, "%")
        .replace(/\\_/g, "_"),
      "i"
    );

  return iLikeToRegExp(`%${search.toLowerCase()}%`).test(text.toLowerCase());
}

<template>
  <v-card
    class="pa-2 px-sm-4 pt-sm-4 pb-sm-8 item"
    color="#ffffff"
    height="100%"
    flat
  >
    <v-sheet
      class="
        d-flex
        align-center
        justify-center
        rounded-pill
        mx-auto
        overflow-hidden
      "
      color="transparent"
      width="80px"
      height="80px"
    >
      <img
        @error="imageError"
        class="d-block item-img"
        :src="files + img + '?resize=1&w=80'"
        width="100%"
        height="100%"
      />
    </v-sheet>

    <div
      class="
        grey--text
        text--darken-3 text-h6 text-center
        font-weight-medium
        mt-5
        item-title
      "
    >
      {{ title }}
    </div>

    <div class="d-flex justify-center my-2 item-notations">
      <v-icon
        v-for="keyNotations in 5"
        :key="keyNotations"
        :color="keyNotations <= notation ? '#ffd700' : 'grey'"
        size="12px"
      >
        fa-star
      </v-icon>
    </div>

    <div
      class="grey--text text--darken-3 text-body-2 text-center mt-3"
      v-html="text"
    ></div>
  </v-card>
</template>

<script>
import DefaultImg from "../../assets/img/default-user.jpg";

export default {
  props: {
    img: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    notation: {
      type: Number,
      required: true,
    },
  },
  methods: {
    imageError(e) {
      e.target.src = DefaultImg;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-title {
    line-height: 24px !important;
  }

  &-img {
    object-fit: contain;
  }

  &-notations {
    gap: 4px;
  }
}
</style>

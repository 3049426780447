<template>
  <v-autocomplete
    :rules="rules"
    v-model="model"
    v-bind="fieldProps"
    :items="items"
    name="what"
    label="O que deseja fazer?"
  ></v-autocomplete>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

export default {
  data: () => ({
    rules: [requiredValidation("Selecione o que deseja fazer.")],
    model: null,
    items: [
      "Quero abrir uma reclamação contra a companhia aérea",
      "Quero entrar com uma ação contra a companhia aérea",
    ],
  }),
  mixins: [mixin],
};
</script>

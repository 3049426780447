<template>
  <div>
    <v-card class="pa-2 pa-sm-4 item" color="transparent" flat tile>
      <v-sheet
        class="d-flex align-center justify-center rounded-pill mx-auto"
        color="secondary"
        width="80px"
        height="80px"
      >
        <span class="white--text text-h4 text-center font-weight-medium">
          {{ itemNumber }}
        </span>
      </v-sheet>

      <div
        class="
          white--text
          text-h6 text-center
          font-weight-medium
          mt-5
          item-title
        "
      >
        {{ title }}
      </div>

      <div class="white--text text-body-2 text-center mt-3" v-html="text"></div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    itemNumber: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-title {
    line-height: 24px !important;
  }
}
</style>

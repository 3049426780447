var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{staticClass:"search"},[_c('v-autocomplete',{staticClass:"rounded-lg form-control",attrs:{"search-input":_vm.search,"append-icon":"fa-search","placeholder":!_vm.xsOnly ? _vm.placeholder : '',"label":_vm.xsOnly ? _vm.placeholder : '',"background-color":"#ffffff","items":_vm.filteredItems,"hide-details":"","hide-no-data":"","item-text":"title","item-value":"title","height":"56px","solo":"","flat":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.openDialog},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.complaintsDialog),callback:function ($$v) {_vm.complaintsDialog=$$v},expression:"complaintsDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"text-subtitle-1 text-md-h6 text-center font-weight-bold mb-2"},[_vm._v(" Qual foi o problema com o seu voo? ")]),_vm._l((_vm.complaints),function(ref,keyComplaints){
var title = ref.title;
return [_c('v-btn',{key:keyComplaints,staticClass:"text-none mt-2 button",attrs:{"min-height":"36px","height":"auto","depressed":"","block":""},on:{"click":function($event){_vm.complaint = title;
            _vm.complaintsDialog = false;
            _vm.optionsDialog = true;}}},[_vm._v(" "+_vm._s(title)+" ")])]})],2)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.optionsDialog),callback:function ($$v) {_vm.optionsDialog=$$v},expression:"optionsDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-btn',{staticClass:"primary--text text--darken-2 text-none font-weight-bold button",attrs:{"href":((_vm.getWhatsappLinkFromPhone(
          this.whatsapp
        )) + "?text=Olá, estou tendo problemas com a " + _vm.company + " e preciso de ajuda para: " + _vm.complaint),"target":"_blank","min-height":"36px","height":"auto","depressed":"","outlined":"","block":""},on:{"click":_vm.closeDialog}},[_vm._v(" Tenho urgência e preciso tirar uma dúvida rápida ")]),_c('v-btn',{staticClass:"\n          primary--text\n          text--darken-2 text-none\n          font-weight-bold\n          mt-3\n          button\n        ",attrs:{"min-height":"36px","height":"auto","depressed":"","outlined":"","block":""},on:{"click":function($event){_vm.formDialog = true;
          _vm.optionsDialog = false;}}},[_vm._v(" Desejo abrir uma Reclamação ")])],1)],1),(_vm.formDialog)?_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('v-card',[_c('ClaimForm',{attrs:{"action":_vm.files + '/rest/email/claim',"response-to":"/obrigado","company":_vm.company,"complaint":_vm.complaint}})],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
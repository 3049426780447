<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget class="secondary--text text--lighten-1">
      {{ item.title }}
    </TitleWidget>

    <div class="text-body-1 mt-6 ck-content" v-html="item.text"></div>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";

const _config = {
  mod: "terms",
};

export default {
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
  },
  components: {
    TitleWidget,
  },
};
</script>

<template>
  <v-text-field
    :rules="rules"
    v-bind="fieldProps"
    class="form-control"
    name="title"
    label="Título da Reclamação"
  ></v-text-field>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

export default {
  data: () => ({
    rules: [requiredValidation("Digite o título da reclamação.")],
  }),
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
.form-control::v-deep {
  input {
    text-transform: uppercase;
  }
}
</style>

<template>
  <div v-if="items.length" class="search">
    <v-autocomplete
      v-model="model"
      :search-input.sync="search"
      @input="openDialog"
      append-icon="fa-search"
      class="rounded-lg form-control"
      :placeholder="!xsOnly ? placeholder : ''"
      :label="xsOnly ? placeholder : ''"
      background-color="#ffffff"
      :items="filteredItems"
      hide-details
      hide-no-data
      item-text="title"
      item-value="title"
      height="56px"
      solo
      flat
    >
    </v-autocomplete>

    <v-dialog v-model="complaintsDialog" max-width="600px">
      <v-card class="pa-4">
        <div
          class="text-subtitle-1 text-md-h6 text-center font-weight-bold mb-2"
        >
          Qual foi o problema com o seu voo?
        </div>

        <template v-for="({ title }, keyComplaints) in complaints">
          <v-btn
            :key="keyComplaints"
            @click="
              complaint = title;
              complaintsDialog = false;
              optionsDialog = true;
            "
            class="text-none mt-2 button"
            min-height="36px"
            height="auto"
            depressed
            block
          >
            {{ title }}
          </v-btn>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog v-model="optionsDialog" max-width="500px">
      <v-card class="pa-4">
        <v-btn
          class="primary--text text--darken-2 text-none font-weight-bold button"
          @click="closeDialog"
          :href="`${getWhatsappLinkFromPhone(
            this.whatsapp
          )}?text=Olá, estou tendo problemas com a ${company} e preciso de ajuda para: ${complaint}`"
          target="_blank"
          min-height="36px"
          height="auto"
          depressed
          outlined
          block
        >
          Tenho urgência e preciso tirar uma dúvida rápida
        </v-btn>

        <v-btn
          @click="
            formDialog = true;
            optionsDialog = false;
          "
          class="
            primary--text
            text--darken-2 text-none
            font-weight-bold
            mt-3
            button
          "
          min-height="36px"
          height="auto"
          depressed
          outlined
          block
        >
          Desejo abrir uma Reclamação
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-if="formDialog" v-model="formDialog" max-width="600px">
      <v-card>
        <ClaimForm
          :action="files + '/rest/email/claim'"
          response-to="/obrigado"
          :company="company"
          :complaint="complaint"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ClaimForm from "./ClaimForm.vue";
import { searchTest } from "../../utils/searchTest";
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

const _config = {
  mod: "airlines",
};

const _data = {
  search: null,
  filteredItems: [],
  placeholder: "Digite o nome da companhia aérea que você está tendo problemas",
  model: null,
  company: null,
  complaint: null,
  complaintsDialog: false,
  optionsDialog: false,
  formDialog: false,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod).get();

    this.$rest("complaintsList").get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ img, title }) => ({
        img: this.files + img,
        title,
      }));
    },
    complaints() {
      return this.$rest("complaintsList").list;
    },
    whatsapp() {
      return this.$rest("informations").item.whatsapp;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    openDialog(v) {
      clearTimeout(this.timeout);

      this.optionsDialog = true;
      // this.complaintsDialog = true;
      this.company = v;

      this.filteredItems = [];

      this.timeout = setTimeout(() => {
        this.model = null;
      }, 500);
    },
    closeDialog() {
      setTimeout(() => {
        this.complaintsDialog = false;
      }, 500);
    },
    getWhatsappLinkFromPhone,
  },
  watch: {
    search(v) {
      if (v === "" || v === null) this.filteredItems = [];
      else
        this.filteredItems = this.items.filter(({ title }) => {
          return searchTest(v, title);
        });
    },
    optionsDialog(v) {
      if (!v && !this.formDialog) this.company = null;
    },
    formDialog(v) {
      if (!v && !this.optionsDialog) this.company = null;
    },
  },
  components: {
    ClaimForm,
  },
};
</script>

<style lang="scss" scoped>
.form-control::v-deep {
  &.v-select.v-select--is-menu-active {
    .v-input__icon--append {
      .v-icon {
        transform: none;
      }
    }
  }
}

.button::v-deep {
  .v-btn__content {
    max-width: 100%;
    text-align: center;
    white-space: normal;
  }
}
</style>

// const server =
//   process.env.NODE_ENV === "development"
//     ? process.env.VUE_APP_SERVER
//     : location.origin;

const server = process.env.VUE_APP_SERVER;

const menu = [
  {
    text: "Home",
    to: "/",
  },
  {
    text: "Como Funciona",
    to: "/como-funciona",
  },
  {
    text: "Conheça os seus Direitos",
    to: "/conheca-os-seus-direitos",
  },
  {
    text: "Sobre Nós",
    to: "/sobre-nos",
  },
];

export { server, menu };

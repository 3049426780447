<template>
  <footer class="shell-footer">
    <v-sheet color="primary" class="position-relative">
      <v-container class="py-8 py-md-12">
        <div class="grid-list">
          <div class="position-relative grid-list-item">
            <v-sheet
              class="mx-auto mx-lg-0"
              color="transparent"
              width="100%"
              max-width="380px"
            >
              <router-link to="/">
                <img
                  v-if="informations.img"
                  class="d-block"
                  src="../../assets/img/reclamepassageiro.com.br.png"
                  width="100%"
                />
              </router-link>

              <div
                class="
                  white--text
                  text-body-1 text-justify
                  font-weight-medium
                  mt-6
                "
              >
                Analisamos casos de violações nos direitos do passageiro aéreo.
              </div>
            </v-sheet>
          </div>

          <div class="d-flex flex-column align-start grid-list-item">
            <div class="position-relative left-border">
              <v-sheet
                width="100%"
                color="transparent"
                class="
                  white--text
                  text-subtitle-1 text-sm-h6
                  font-weight-medium
                  px-4
                "
              >
                Política de Uso
              </v-sheet>

              <v-btn
                class="text-body-1 text-none btn-contact"
                height="auto"
                min-height="36px"
                to="/termos-e-condicoes-de-uso"
                title="Termos e condições de uso"
                color="white"
                text
              >
                Termos e condições de uso
              </v-btn>

              <v-btn
                class="text-body-1 text-none btn-contact"
                height="auto"
                min-height="36px"
                to="/politica-de-privacidade-e-uso-de-dados-pessoais"
                title="Política de privacidade e uso de dados pessoais"
                color="white"
                text
              >
                Política de privacidade e uso de dados pessoais
              </v-btn>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column
              align-start
              position-relative
              grid-list-item
            "
          >
            <div class="position-relative left-border">
              <v-sheet
                width="100%"
                color="transparent"
                class="
                  white--text
                  text-subtitle-1 text-sm-h6
                  font-weight-medium
                  px-4
                "
              >
                Contatos
              </v-sheet>

              <div v-for="(item, keyContact) in contacts" :key="keyContact">
                <v-btn
                  class="white--text text-body-2 text-none btn-contact"
                  height="auto"
                  min-height="36px"
                  :href="item.link"
                  :target="item.target"
                  :title="item.name"
                  depressed
                  color="transparent"
                >
                  <v-icon v-if="item.icon" :size="item.size" class="mr-2">
                    {{ item.icon }}
                  </v-icon>

                  <span v-html="item.text"></span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet color="primary darken-2">
      <v-container
        class="
          pt-0
          pb-5
          d-flex
          flex-column flex-sm-row
          justify-space-between
          align-center
          text-center
        "
      >
        <div class="white--text text-body-2 mt-5">
          Copyright
          <b>{{ informations.short_name }}</b>
          © {{ year }}
          <span class="d-block d-sm-inline-block">
            Todos os direitos reservados
          </span>
        </div>

        <a
          class="d-flex mt-5"
          href="https://www.mrxweb.com.br/"
          target="_blank"
        >
          <img
            class="shell-footer-logo"
            src="../../assets/img/mrxweb.svg"
            alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            height="20px"
          />
        </a>
      </v-container>
    </v-sheet>
  </footer>
</template>

<script>
import { getContact } from "../../utils/getContact";

export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    contacts() {
      return getContact(this.informations);
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-footer {
  .grid-list {
    display: grid;
    gap: 24px 48px;
    grid-template-columns: 380px 300px auto;

    @media screen and (min-width: 600.001px) and (max-width: 1264px) {
      gap: 24px;
      grid-template-columns: auto auto;

      &-item:first-child {
        grid-column-start: 1;
        grid-column-end: -1;
      }
    }

    &-item {
      .left-border {
        &:before {
          background-color: white;
          bottom: 0;
          content: "";
          left: 0;
          position: absolute;
          top: 0;
          width: 1px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .btn-contact::v-deep {
      .v-btn__content {
        max-width: 100%;
        white-space: normal;
      }
    }
  }
}
</style>
